import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
// import CategoryLv1 from "@/views/CategoryLv1/Index.vue";
// import CategoryLv2 from "@/views/CategoryLv2/Index.vue";
// import CategoryLv3 from "@/views/CategoryLv3/Index.vue";
import Banner from "@/views/Banner/Index.vue";
import BannerNew from "@/views/Banner/New.vue";
import BannerEdit from "@/views/Banner/Edit.vue";
import Promotion from "@/views/Promotion/Index.vue";
import PromotionEdit from "@/views/Promotion/Edit.vue";
import BannerHomeSort from "@/views/Banner/HomeSort.vue";
import BannerCategorySort from "@/views/Banner/CategorySort.vue";
// import HomeBanner from "@/views/HomeBanner/Index.vue";
// import CatBanner from "@/views/CatBanner/Index.vue";
import PartnershipShops from "@/views/PartnershipShops/Index.vue";
import PartnershipShopsEdit from "@/views/PartnershipShops/Edit.vue";
import ValuedPack from "@/views/ValuedPack/Index.vue";
import ValuedPackEdit from "@/views/ValuedPack/Edit.vue";
import NewArrival from "@/views/NewArrival/Index.vue";
import NewArrivalEdit from "@/views/NewArrival/Edit.vue";
import BestSeller from "@/views/BestSeller/Index.vue";
import BestSellerEdit from "@/views/BestSeller/Edit.vue";
import GrabAndGo from "@/views/GrabAndGo/Index.vue";
import GrabAndGoEdit from "@/views/GrabAndGo/Edit.vue";
import PreOrder from "@/views/PreOrder/Index.vue";
import PreOrderEdit from "@/views/PreOrder/Edit.vue";
import Health from "@/views/Health/Index.vue";
import HealthEdit from "@/views/Health/Edit.vue";
import SpecialOffer from "@/views/SpecialOffer/Index.vue";
import SpecialOfferEdit from "@/views/SpecialOffer/Edit.vue";
// import Iceland from "@/views/Iceland/Index.vue";
// import IcelandEdit from "@/views/Iceland/Edit.vue";
// import Monoprix from "@/views/Monoprix/Index.vue";
// import MonoprixEdit from "@/views/Monoprix/Edit.vue";
// import Woolworths from "@/views/Woolworths/Index.vue";
// import WoolworthsEdit from "@/views/Woolworths/Edit.vue";
import Order from "@/views/Order/Index.vue";
import OrderEdit from "@/views/Order/Edit.vue";
import OrderCancel from "@/views/Order/Cancel.vue";
import PreOrderOrder from "@/views/PreOrderOrder/Index.vue";
import PreOrderOrderEdit from "@/views/PreOrderOrder/Edit.vue";
import Nationwide from "@/views/Nationwide/Index.vue";
import NationwideEdit from "@/views/Nationwide/Edit.vue";
import Picking from "@/views/Picking/Index.vue";
import PickingEdit from "@/views/Picking/Edit.vue";
import AssignPicker from "@/views/Picking/AssignPicker.vue";
import PrintSticker from "@/views/Picking/PrintSticker.vue";
import Product from "@/views/Product/Index.vue";
import ProductNew from "@/views/Product/New.vue";
import ProductEdit from "@/views/Product/Edit.vue";
import ProductSeq from "@/views/Product/Seq.vue";
import ProductSearchSeq from "@/views/Product/SearchSeq.vue";
import ProductImport from "@/views/Product/Import.vue";
import ProductInfoImport from "@/views/Product/ImportProductInfo.vue";
import ProductRangeImport from "@/views/Product/ImportProductRange.vue";
import ProductMetaImport from "@/views/Product/ImportProductMeta.vue";
import ProductAttributeAlcoholImport from "@/views/Product/ImportProductAttributeAlcohol.vue";
import ProductAttributeButcherImport from "@/views/Product/ImportProductAttributeButcher.vue";
import ProductAttributeFruitImport from "@/views/Product/ImportProductAttributeFruit.vue";
import ProductAttributeHbaImport from "@/views/Product/ImportProductAttributeHba.vue";
import ProductAttributeOthersImport from "@/views/Product/ImportProductAttributeOthers.vue";
import ProductRelatedImport from "@/views/Product/ImportProductRelated.vue";
import ProductRecommended from "@/views/ProductRecommended/Index.vue";
import ProductRecommendedEdit from "@/views/ProductRecommended/Edit.vue";
import ManualStock from "@/views/ManualStock/Index.vue";
import ManualStockEdit from "@/views/ManualStock/Edit.vue";
// import ButcherShop from "@/views/ButcherShop/Index.vue";
// import ButcherShopEdit from "@/views/ButcherShop/Edit.vue";

// import FestiveItemsEdit from "@/views/FestiveItems/Edit.vue";
import GourmetLineEdit from "@/views/GourmetLine/Edit.vue";
import HampersFlowersEdit from "@/views/HampersFlowers/Edit.vue";
import YouTubeEdit from "@/views/YouTube/Edit.vue";

import Coupon from "@/views/Coupon/Index.vue";
import CouponEdit from "@/views/Coupon/Edit.vue";

import Coupon3 from "@/views/Coupon3/Index.vue";
import Coupon3Edit from "@/views/Coupon3/Edit.vue";

import CouponGroup from "@/views/CouponGroup/Index.vue";
import CouponGroupEdit from "@/views/CouponGroup/Edit.vue";
import User from "@/views/User/Index.vue";
import UserEdit from "@/views/User/Edit.vue";
import ReadyToGo from "@/views/ReadyToGo/Index.vue";
import ReadyToGoEdit from "@/views/ReadyToGo/Edit.vue";
import Voucher from "@/views/Voucher/Index.vue";
import VoucherEdit from "@/views/Voucher/Edit.vue";
import AutoRefund from "@/views/Voucher/AutoRefund.vue";
import VoucherRequest from "@/views/Voucher/VoucherRequest.vue";
import EditRequest from "@/views/Voucher/EditRequest.vue";
// import Lazada from "@/views/Lazada/Index.vue";
// import LazadaEdit from "@/views/Lazada/Edit.vue";
import Seafood from "@/views/Seafood/Index.vue";
import SeafoodEdit from "@/views/Seafood/Edit.vue";
import Seasonal from "@/views/Seasonal/Index.vue";
import SeasonalEdit from "@/views/Seasonal/Edit.vue";
// import FruitsAndVegetables from "@/views/FruitsAndVegetables/Index.vue";
// import FruitsAndVegetablesEdit from "@/views/FruitsAndVegetables/Edit.vue";
import Summer from "@/views/Summer/Index.vue";
import SummerEdit from "@/views/Summer/Edit.vue";

import Beef from "@/views/Beef/Index.vue";
import BeefEdit from "@/views/Beef/Edit.vue";
import Pork from "@/views/Pork/Index.vue";
import PorkEdit from "@/views/Pork/Edit.vue";
import ChickenAndDuck from "@/views/ChickenAndDuck/Index.vue";
import ChickenAndDuckEdit from "@/views/ChickenAndDuck/Edit.vue";
import Lamb from "@/views/Lamb/Index.vue";
import LambEdit from "@/views/Lamb/Edit.vue";
import Fruits from "@/views/Fruits/Index.vue";
import FruitsEdit from "@/views/Fruits/Edit.vue";
import Vegetables from "@/views/Vegetables/Index.vue";
import VegetablesEdit from "@/views/Vegetables/Edit.vue";
import OnlyAtVilla from "@/views/OnlyAtVilla/Index.vue";
import OnlyAtVillaEdit from "@/views/OnlyAtVilla/Edit.vue";

import Article from "@/views/Article/Index.vue";
import ArticleEdit from "@/views/Article/Edit.vue";
import Inventory from "@/views/Inventory/Index.vue";
// import SearchProduct from "@/views/Product/Search.vue";
import ProductInventory from "@/views/Product/Inventory.vue";
import SaleReport from "@/views/Dashboard/SaleReport.vue";
import DailySummary from "@/views/Dashboard/DailySummary.vue";
import OnlineWarehouse from "@/views/OnlineWarehouse/Index.vue";
import Reports from "@/views/Reports/Index.vue";
import PickingReport from "@/views/Reports/PickingReport.vue";
// import OOSReport from "@/views/Reports/OOSReport.vue";
import SaleOrderReport from "@/views/Reports/SaleOrderReport.vue";
import NextDayOrder from "@/views/Reports/NextDayOrder.vue";
import SalesByStore from "@/views/Reports/SalesByStore.vue";
import SalesTrend from "@/views/Reports/SalesTrend.vue";
import SalesDashboard from "@/views/Reports/SalesDashboard.vue";
import Chat from "@/views/Chat.vue";
import Report from "@/views/Report.vue";

import GeoContent from "@/views/GeoContent/Index.vue";
import GeoContentEdit from "@/views/GeoContent/Edit.vue";
import GeoProduct from "@/views/GeoContent/Product.vue";
import GeoProductEdit from "@/views/GeoContent/ProductEdit.vue";
import CampaignsContent from "@/views/CampaignsContent/Index.vue";
import CampaignsContentEdit from "@/views/CampaignsContent/Edit.vue";
import SendNotify from "@/views/SendNotify/Index.vue";
import SendNotifyEdit from "@/views/SendNotify/Edit.vue";

import CustomerSavedCard from "@/views/CustomerSavedCard/Index.vue";
import PendingItems from "@/views/PendingItems/Index.vue";

import DeliveryStatus from "@/views/DeliveryStatus/Index.vue";
import SendNoti from "@/views/SendNoti/Index.vue";
import CampaignTracking from "@/views/CampaignTracking/Index.vue";

import Survey from "@/views/Survey/Index.vue";
import SurveyEdit from "@/views/Survey/Edit.vue";

import ProductRating from "@/views/ProductRating/Index.vue";
import ProductRatingEdit from "@/views/ProductRating/Edit.vue";

import { checkLogin } from "@/services/lambdaApi";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { keepAlive: false, isAlway: true }
  }, 
  {
    path: "/sendnoti",
    name: "sendnoti",
    component: SendNoti,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/campaigntracking",
    name: "campaigntracking",
    component: CampaignTracking,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/deliverystatus/:b/:d",
    name: "deliverystatus",
    component: DeliveryStatus,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/deliverystatus/:b",
    name: "deliverystatus2",
    component: DeliveryStatus,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/deliverystatus",
    name: "deliverystatus3",
    component: DeliveryStatus,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/home",
    name: "home",
    component: Home,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: ForgotPassword,
    meta: { keepAlive: false, isAlway: true }
  }, 
  {
    path: "/dashboard/salereport",
    name: "salereport",
    component: SaleReport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/dashboard/dailysummary",
    name: "dailysummary",
    component: DailySummary,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/onlinewarehouse/index",
    name: "onlinewarehouse",
    component: OnlineWarehouse,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/Reports/index",
    name: "reports",
    component: Reports,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/Reports/pickingreport",
    name: "pickingreport",
    component: PickingReport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  // {
  //   path: "/Reports/oosreport",
  //   name: "oosreport",
  //   component: OOSReport,
  //   meta: { keepAlive: true }
  // }, 
  {
    path: "/Reports/saleorderreport",
    name: "saleorderreport",
    component: SaleOrderReport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/Reports/nextdayorder",
    name: "nextdayorder",
    component: NextDayOrder,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/Reports/salesbystore",
    name: "salesbystore",
    component: SalesByStore,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/Reports/salestrend",
    name: "salestrend",
    component: SalesTrend,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/Reports/salesdashboard",
    name: "salesdashboard",
    component: SalesDashboard,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  // {
  //   path: "/category_lv1/index",
  //   name: "category_lv1",
  //   component: CategoryLv1,
  //   beforeEnter(to, from, next) { checkLogin(to, from, next) },
  //   meta: { keepAlive: true }
  // }, 
  // {
  //   path: "/category_lv2/index",
  //   name: "category_lv2",
  //   component: CategoryLv2,
  //   beforeEnter(to, from, next) { checkLogin(to, from, next) },
  //   meta: { keepAlive: true }
  // }, 
  // {
  //   path: "/category_lv3/index",
  //   name: "category_lv3",
  //   component: CategoryLv3,
  //   beforeEnter(to, from, next) { checkLogin(to, from, next) },
  //   meta: { keepAlive: true }
  // }, 
  {
    path: "/banner/index",
    name: "banner",
    component: Banner,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/banner/new",
    name: "banner-new",
    component: BannerNew,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/banner/homesort",
    name: "banner-home-sort",
    component: BannerHomeSort,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/banner/categorysort",
    name: "banner-category-sort",
    component: BannerCategorySort,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/banner/edit/:id",
    name: "banner-edit",
    component: BannerEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/promotion/index",
    name: "promotion",
    component: Promotion,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/promotion/edit/:id",
    name: "promotion-edit",
    component: PromotionEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  // {
  //   path: "/home_banner/index",
  //   name: "home_banner",
  //   component: HomeBanner,
  //   meta: { keepAlive: false }
  // }, 
  // {
  //   path: "/cat_banner/index",
  //   name: "cat_banner",
  //   component: CatBanner,
  //   meta: { keepAlive: false }
  // }, 
  {
    path: "/product/index",
    name: "product",
    component: Product,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/product/new",
    name: "product-new",
    component: ProductNew,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/edit/:id",
    name: "product-edit",
    component: ProductEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/seq",
    name: "product-seq",
    component: ProductSeq,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/product/searchseq",
    name: "product-search-seq",
    component: ProductSearchSeq,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/product/import",
    name: "product-import",
    component: ProductImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductinfo",
    name: "product-info-import",
    component: ProductInfoImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductrange",
    name: "product-range-import",
    component: ProductRangeImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductmeta",
    name: "product-meta-import",
    component: ProductMetaImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductattributealcohol",
    name: "product-attribute-alcohol-import",
    component: ProductAttributeAlcoholImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductattributebutcher",
    name: "product-attribute-butcher-import",
    component: ProductAttributeButcherImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductattributefruit",
    name: "product-attribute-fruit-import",
    component: ProductAttributeFruitImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductattributehba",
    name: "product-attribute-hba-import",
    component: ProductAttributeHbaImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductattributeothers",
    name: "product-attribute-others-import",
    component: ProductAttributeOthersImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/product/importproductrelated",
    name: "product-related-import",
    component: ProductRelatedImport,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/partnershipshops/index",
    name: "partnershipshops",
    component: PartnershipShops,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/partnershipshops/edit/:id/:gid",
    name: "partnershipshops-edit",
    component: PartnershipShopsEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/valuedpack/index",
    name: "valuedpack",
    component: ValuedPack,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/valuedpack/edit/:id/:gid",
    name: "valuedpack-edit",
    component: ValuedPackEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/newarrival/index",
    name: "newarrival",
    component: NewArrival,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/newarrival/edit/:id",
    name: "newarrival-edit",
    component: NewArrivalEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/bestseller/index",
    name: "bestseller",
    component: BestSeller,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/bestseller/edit/:id",
    name: "bestseller-edit",
    component: BestSellerEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/grabandgo/index",
    name: "grabandgo",
    component: GrabAndGo,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/grabandgo/edit/:id/:gid",
    name: "grabandgo-edit",
    component: GrabAndGoEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/preorder/index",
    name: "preorder",
    component: PreOrder,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/preorder/edit/:id/:gid",
    name: "preorder-edit",
    component: PreOrderEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/health/index",
    name: "health",
    component: Health,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/health/edit/:id/:gid",
    name: "health-edit",
    component: HealthEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/seafood/index",
    name: "seafood",
    component: Seafood,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/seafood/edit/:id/:gid",
    name: "seafood-edit",
    component: SeafoodEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/seasonal/index",
    name: "seasonal",
    component: Seasonal,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/seasonal/edit/:id/:gid",
    name: "seasonal-edit",
    component: SeasonalEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  // {
  //   path: "/fruitsandvegetables/index",
  //   name: "fruitsandvegetables",
  //   component: FruitsAndVegetables,
  //   meta: { keepAlive: true }
  // },
  // {
  //   path: "/fruitsandvegetables/edit/:id/:gid",
  //   name: "fruitsandvegetables-edit",
  //   component: FruitsAndVegetablesEdit,
  //   meta: { keepAlive: false }
  // },
  {
    path: "/summer/index",
    name: "summer",
    component: Summer,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/summer/edit/:id/:gid",
    name: "summer-edit",
    component: SummerEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },

  {
    path: "/beef/index",
    name: "beef",
    component: Beef,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/beef/edit/:id/:gid",
    name: "beef-edit",
    component: BeefEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/pork/index",
    name: "pork",
    component: Pork,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/pork/edit/:id/:gid",
    name: "pork-edit",
    component: PorkEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/chickenandduck/index",
    name: "chickenandduck",
    component: ChickenAndDuck,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/chickenandduck/edit/:id/:gid",
    name: "chickenandduck-edit",
    component: ChickenAndDuckEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/lamb/index",
    name: "lamb",
    component: Lamb,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/lamb/edit/:id/:gid",
    name: "lamb-edit",
    component: LambEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/fruits/index",
    name: "fruits",
    component: Fruits,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/fruits/edit/:id/:gid",
    name: "fruits-edit",
    component: FruitsEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/vegetables/index",
    name: "vegetables",
    component: Vegetables,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/vegetables/edit/:id/:gid",
    name: "vegetables-edit",
    component: VegetablesEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/onlyatvilla/index",
    name: "onlyatvilla",
    component: OnlyAtVilla,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/onlyatvilla/edit/:id/:gid",
    name: "onlyatvilla-edit",
    component: OnlyAtVillaEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  
  {
    path: "/specialoffer/index",
    name: "specialoffer",
    component: SpecialOffer,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/specialoffer/edit/:id",
    name: "specialoffer-edit",
    component: SpecialOfferEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  // {
  //   path: "/iceland/index",
  //   name: "iceland",
  //   component: Iceland,
  //   meta: { keepAlive: true }
  // }, 
  // {
  //   path: "/iceland/edit/:id/:gid",
  //   name: "iceland-edit",
  //   component: IcelandEdit,
  //   meta: { keepAlive: false }
  // },
  // {
  //   path: "/monoprix/index",
  //   name: "monoprix",
  //   component: Monoprix,
  //   meta: { keepAlive: true }
  // }, 
  // {
  //   path: "/monoprix/edit/:id/:gid",
  //   name: "monoprix-edit",
  //   component: MonoprixEdit,
  //   meta: { keepAlive: false }
  // },
  // {
  //   path: "/woolworths/index",
  //   name: "woolworths",
  //   component: Woolworths,
  //   meta: { keepAlive: true }
  // }, 
  // {
  //   path: "/woolworths/edit/:id/:gid",
  //   name: "woolworths-edit",
  //   component: WoolworthsEdit,
  //   meta: { keepAlive: false }
  // },
  {
    path: "/productrecommended/index",
    name: "productrecommended",
    component: ProductRecommended,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/productrecommended/edit/:id",
    name: "productrecommended-edit",
    component: ProductRecommendedEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/article/index",
    name: "article",
    component: Article,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/article/edit/:id",
    name: "article-edit",
    component: ArticleEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  // {
  //   path: "/festiveitems/edit",
  //   name: "festiveitems-edit",
  //   component: FestiveItemsEdit,
  //   meta: { keepAlive: false }
  // }, 
  {
    path: "/gourmetline/edit",
    name: "gourmetline-edit",
    component: GourmetLineEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/hampersflowers/edit",
    name: "hampersflowers-edit",
    component: HampersFlowersEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/youtube/edit",
    name: "youtube-edit",
    component: YouTubeEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/inventory/index",
    name: "inventory",
    component: Inventory,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  // {
  //   path: "/product/search",
  //   name: "searchproduct",
  //   component: SearchProduct,
  //   meta: { keepAlive: true }
  // }, 
  {
    path: "/product/inventory/:id",
    name: "productinventory",
    component: ProductInventory,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },   
  {
    path: "/manualstock/index",
    name: "manualstock",
    component: ManualStock,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/manualstock/edit/:id",
    name: "manualstock-edit",
    component: ManualStockEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  // {
  //   path: "/butchershop/index",
  //   name: "butchershop",
  //   component: ButcherShop,
  //   meta: { keepAlive: true }
  // }, 
  // {
  //   path: "/butchershop/edit/:id/:gid",
  //   name: "butchershop-edit",
  //   component: ButcherShopEdit,
  //   meta: { keepAlive: false }
  // },
  {
    path: "/coupongroup/index",
    name: "coupongroup",
    component: CouponGroup,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/coupongroup/edit/:id",
    name: "coupongroup-edit",
    component: CouponGroupEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/coupon/index",
    name: "coupon",
    component: Coupon,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/coupon/edit/:id",
    name: "coupon-edit",
    component: CouponEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/coupon3/index",
    name: "coupon3",
    component: Coupon3,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/coupon3/edit/:id",
    name: "coupon3-edit",
    component: Coupon3Edit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/order/index",
    name: "order",
    component: Order,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/order/edit/:id",
    name: "order-edit",
    component: OrderEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/order/cancel",
    name: "order-cancel",
    component: OrderCancel,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/preorderorder/index",
    name: "preorderorder",
    component: PreOrderOrder,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/preorderorder/edit/:id",
    name: "preorderorder-edit",
    component: PreOrderOrderEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/nationwide/index",
    name: "nationwide",
    component: Nationwide,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/nationwide/edit/:id",
    name: "nationwide-edit",
    component: NationwideEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/picking/index",
    name: "picking",
    component: Picking,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/picking/edit/:id",
    name: "picking-edit",
    component: PickingEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false },
  },
  {
    path: "/picking/assignpicker",
    name: "assignpicker",
    component: AssignPicker,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/picking/printsticker",
    name: "printsticker",
    component: PrintSticker,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/user/index",
    name: "user",
    component: User,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/user/edit/:id",
    name: "user-edit",
    component: UserEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/sendnotify/index",
    name: "sendnotify",
    component: SendNotify,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/sendnotify/edit/:id",
    name: "sendnotify-edit",
    component: SendNotifyEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/readytogo/index",
    name: "readytogo",
    component: ReadyToGo,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/readytogo/edit/:id/:gid",
    name: "readytogo-edit",
    component: ReadyToGoEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/voucher/index",
    name: "voucher",
    component: Voucher,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/voucher/edit/:id",
    name: "voucher-edit",
    component: VoucherEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/voucher/autorefund",
    name: "autorefund",
    component: AutoRefund,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/voucher/voucherrequest",
    name: "voucherrequest",
    component: VoucherRequest,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  },
  {
    path: "/voucher/editrequest/:id",
    name: "edit-request",
    component: EditRequest,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false },
  },
  // {
  //   path: "/lazada/index",
  //   name: "lazada",
  //   component: Lazada,
  //   beforeEnter(to, from, next) { checkLogin(to, from, next) },
  //   meta: { keepAlive: false }
  // }, 
  // {
  //   path: "/lazada/edit/:id",
  //   name: "lazada-edit",
  //   component: LazadaEdit,
  //   beforeEnter(to, from, next) { checkLogin(to, from, next) },
  //   meta: { keepAlive: false }
  // },

  {
    path: "/report",
    name: "report",
    component: Report,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/chat",
    name: "chat",
    component: Chat,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false, isPopup: true }
  }, 
  {
    path: "/geocontent/index",
    name: "geocontent",
    component: GeoContent,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/geocontent/edit",
    name: "geocontent-edit2",
    component: GeoContentEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/geocontent/edit/:id",
    name: "geocontent-edit",
    component: GeoContentEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/geocontent/product",
    name: "geoproduct",
    component: GeoProduct,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/geocontent/productedit/:id",
    name: "geoproduct-edit",
    component: GeoProductEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/campaignscontent/index",
    name: "campaignscontent",
    component: CampaignsContent,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/campaignscontent/edit",
    name: "campaignscontent-edit2",
    component: CampaignsContentEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/campaignscontent/edit/:id",
    name: "campaignscontent-edit",
    component: CampaignsContentEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/customersavedcard/index",
    name: "customersavedcard",
    component: CustomerSavedCard,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/pendingitems/index",
    name: "pendingitems",
    component: PendingItems,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/survey/index",
    name: "survey",
    component: Survey,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }, 
  {
    path: "/survey/edit",
    name: "survey-edit2",
    component: SurveyEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/survey/edit/:id",
    name: "survey-edit",
    component: SurveyEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/productrating/index",
    name: "productrating",
    component: ProductRating,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: true }
  }, 
  {
    path: "/productrating/edit",
    name: "productrating-edit2",
    component: ProductRatingEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/productrating/edit/:id",
    name: "productrating-edit",
    component: ProductRatingEdit,
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: { keepAlive: false }
  },
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "*",
    redirect: "/login"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
